import SlideShowGallery_SlideShowTextOverlayComponent from '../components/NativeGalleries/SlideShowGallery/viewer/skinComps/SlideShowTextOverlay/SlideShowTextOverlay.skin';
import SlideShowGallery_SlideShowTextOverlayController from '../components/NativeGalleries/SlideShowGallery/viewer/SlideShowGallery.controller';


const SlideShowGallery_SlideShowTextOverlay = {
  component: SlideShowGallery_SlideShowTextOverlayComponent,
  controller: SlideShowGallery_SlideShowTextOverlayController
};


export const components = {
  ['SlideShowGallery_SlideShowTextOverlay']: SlideShowGallery_SlideShowTextOverlay
};

